:root {
  --header-message-height: 33px;
}

.headerMessage {
  height: var(--header-message-height);

  &Background {
    background: var(--color-danger-dark);
    box-shadow: var(--shadow-header-default);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--header-message-height);
    padding: 8px 0;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    left: 0;
    animation: blink 2s 1;
    transition: all 0.1s linear;
  }

  p {
    color: var(--color-background-white);
    margin: 0;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-semibold);
  }
}

@keyframes blink {
  50% {
    background: var(--color-danger);
  }
}
