.labeledCardRow {
  &Date {
    align-items: flex-start;
  }

  &Date,
  & > div {
    margin: 16px 0;
  }

  &Date > div,
  & > div {
    flex: 1;
  }
}
