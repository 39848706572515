.item {
  display: flex;
  background-color: var(--color-neutral-light-100);
  box-shadow: var(--shadow-header-default);
  border-radius: var(--border-radius-default);
  margin-bottom: 24px;
  padding: 16px 24px;
  gap: 32px;

  &Col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > span {
      display: block;
      margin-bottom: 4px;
    }

    & h6 {
      white-space: nowrap;
    }

    &Status h6 > div {
      width: 300px;
    }
  }

  &:hover {
    background: var(--color-neutral-light-200);
  }

  &HasDetails {
    cursor: pointer;
  }
}

.openButton {
  font-size: var(--font-size-normal);
  white-space: nowrap;
}
