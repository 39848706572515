.status {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-normal);
  text-transform: uppercase;
  line-height: var(--line-height-lg);
  letter-spacing: var(--letter-space-xs);
  white-space: nowrap;
  max-width: 300px;

  &Bg {
    background-color: var(--color-neutral-100);
    color: var(--color-grayish);
    border-radius: var(--border-radius-default);
    display: inline-block;
    padding: 4px 6px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &Success {
      background-color: var(--color-success-dark);
      color: var(--color-primary-30);
    }

    &Failed {
      background-color: var(--color-danger-dark);
      color: var(--color-primary-30);
    }

    &Pending {
      background-color: var(--color-alert);
      color: var(--color-primary);
    }
  }
}
